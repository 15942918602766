<template>
  <div
    class="box is-clipped is-relative has-background-primary has-text-white is-flex is-flex-direction-row"
    :style="{ 'background-color': customBackgroundColor + ' !important' }"
    :class="{ 'box-with-circles': !isUpgradeEvent, 'bigger': showBiggerTicket }"
  >
    <div class="is-flex-grow-1">
      <p class="is-size-7 has-text-weight-normal">{{ type }}</p>
      <div class="ticket-card-inner-container">
        <div class="ticket-card-title">
          <p class="is-size-5 has-text-weight-bold">{{ title }}</p>
        </div>
        <div class="ticket-card-price" :class="{'ticket-card-price-upgrade-event': isUpgradeEvent}" v-if="!priceInvisible">
          <p class="is-size-5 has-text-weight-bold" :style="getCustomPriceTextColor">
            {{ formattedPrice(price, { locale: "pl-PL", currency: currency }) + priceSuffix }}
          </p>
        </div>
      </div>
    </div>
    <div class="">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TicketCard",
  props: ["id", "type", "title", "price", "currency", "priceInvisible", 'customBackgroundColor'],

  filters: {
    // currency(value, { locale, currency }) {
    //   const opts = { style: "currency", currency: currency, minimumFractionDigits: 2 };
    //   const formatter = new Intl.NumberFormat(locale, opts);
    //   const formatted = formatter.format(Number(value));
    //   return formatted.replace(/,00/, "");
    // },
  },

  methods: {
    formattedPrice(value, { locale, currency }) {
      const opts = { style: "currency", currency: currency, minimumFractionDigits: 2 };
      const formatter = new Intl.NumberFormat(locale, opts);
      const formatted = formatter.format(Number(value));
      return formatted.replace(/,00/, "");
    },
  },

  computed: {
    ...mapState(["eventId"]),
    isUpgradeEvent() {
      return this.eventId === 1355;
    },
    
    isAIBAConference() {
      return this.eventId === 1559;
    },

    showBiggerTicket() {
      return this.id === 726 || this.id === 728;
    },

    getCustomPriceTextColor() {
      if (this.isAIBAConference) {
        return { 'color': 'var(--primary-color) !important' };
      } else {
        return {};
      }
    },

    priceSuffix() {
      if (this.isUpgradeEvent) {
        return ` + ${this.$t("payment.vat")}`;
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding: 16px;
  line-height: 24px;
}

.bigger {
  padding: 40px 16px !important;
}

.box-with-circles {
  &::before,
  &::after {
    content: "";
    background: #ffffff;
    background: var(--primary-color-text);
    border-radius: 1000px;
    opacity: 0.12;
    position: absolute;
  }

  &::before {
    width: 40px;
    height: 40px;
    bottom: -20px;
    left: -15px;
  }

  &::after {
    width: 108px;
    height: 108px;
    top: -40px;
    right: -45px;
  }
}

.ticket-card-inner-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;

  .ticket-card-title {
    flex: 1 0;
  }

  .ticket-card-price {
    flex: 0 1;
  }

  .ticket-card-price-upgrade-event {
    flex: 0 1 125px;
  }
}
</style>
